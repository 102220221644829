/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    USERID: number;
    LANGUAGE: User.LANGUAGEEnum;
    ROLEWEIGHT: number;
    DOMAIN: string;
    ROLE: string;
    EMAIL: string;
    NAME: string;
    PORTALURL: string;
    DATASOURCE: string;
    ISADMIN?: boolean;
    roles?: Array<User.RolesEnum>;
}
export namespace User {
    export type LANGUAGEEnum = 'en' | 'du' | 'fr';
    export const LANGUAGEEnum = {
        En: 'en' as LANGUAGEEnum,
        Du: 'du' as LANGUAGEEnum,
        Fr: 'fr' as LANGUAGEEnum
    };
    export type RolesEnum = 'main' | 'admin' | 'member';
    export const RolesEnum = {
        Main: 'main' as RolesEnum,
        Admin: 'admin' as RolesEnum,
        Member: 'member' as RolesEnum
    };
}


