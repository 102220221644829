/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject3 { 
    resolution?: InlineObject3.ResolutionEnum;
}
export namespace InlineObject3 {
    export type ResolutionEnum = 'apply' | 'withdraw';
    export const ResolutionEnum = {
        Apply: 'apply' as ResolutionEnum,
        Withdraw: 'withdraw' as ResolutionEnum
    };
}


