/**
 * C8-mobile-web-app
 * C8-mobile-web-app api
 *
 * The version of the OpenAPI document: 1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InlineObject2 { 
    resolution?: InlineObject2.ResolutionEnum;
    refuse_reason?: string;
}
export namespace InlineObject2 {
    export type ResolutionEnum = 'accept' | 'refuse';
    export const ResolutionEnum = {
        Accept: 'accept' as ResolutionEnum,
        Refuse: 'refuse' as ResolutionEnum
    };
}


