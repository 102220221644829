import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { TranslatorService } from './translator.service';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    params: any;
    constructor(private router: Router, private route: ActivatedRoute) {
        route.queryParams.subscribe((next: any) => {
            this.params = next;
        });
    }

    public getParam(param: string): string {
        if (!param || !this.params[param]) {
            return '';
        } else return this.params[param];
    }
    public getParams(): any {
        return this.params;
    }
    public getParamsString(): any {
        let result = '';
        const params = this.getParams();
        for (const param in params) {
            result += `&${param}=${escape(params[param])}`;
        }
        if (result.length) {
            result = result.replace(/^./g, '');
        }
        return result;
    }
    public addParam(param: string, value: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: value
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    public removeParam(param: string) {
        if (!param) {
            return;
        } else {
            this.router.navigate([], {
                queryParams: {
                    [param]: null
                },
                queryParamsHandling: 'merge'
            });
        }
    }
    // object is empty or only empty keys (null | undefined | '')
    public objectIsEmpty(object: any) {
        if (object.constructor != Object) return true;
        for (const key in object) {
            if (Object.prototype.hasOwnProperty.call(object, key)) {
                if (object[key] === null || object[key] === undefined || object[key] === '') {
                    continue;
                } else return false;
            }
        }
        return true;
    }
    public downloadBlob(blob: Blob, filename: string) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        let blobUrl = window.URL.createObjectURL(blob);
        a.href = blobUrl;
        a.download = filename;
        a.click();
        setTimeout(function () {
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
        }, 0);
    }
    public copyToClipboard(val: string, successMessage: string) {
        if (!val) {
            return;
        }
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    public toTop(anchor?: string) {
        if (!anchor) {
            window.scrollTo(0, 0);
        } else {
            const el = document.getElementById(anchor);
            if (el) {
                const top = el.getBoundingClientRect().top + window.pageYOffset - 24;
                // window.scrollTo(0, top);
                window.scrollTo({ top: top, behavior: 'smooth' });
            } else {
                window.scrollTo(0, 0);
            }
        }
    }

    public isDefined(item) {
        return item != undefined && item != null;
    }
}
