import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DefaultService } from '../../../../../../shared/api';
import { HelpersService } from 'src/app/services/helpers.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TranslatePipe } from 'src/app/utils/pipes';

@Component({
    selector: 'app-job',
    templateUrl: './job.component.html',
    styleUrls: ['./job.component.scss']
})
export class JobComponent implements OnInit, OnChanges {
    @Input() job: any = {};
    @Input() submitting: boolean = false;
    @Input() toAccept: boolean = false;
    @Input() isOpen: boolean = false;
    @Input() isInfo: boolean = false;
    @Input() expanded: boolean = false;
    @Input() isControlcenter: boolean = false;
    @Output() accept = new EventEmitter();
    @Output() decline = new EventEmitter();
    @Output() apply = new EventEmitter();
    @Output() withdraw = new EventEmitter();
    @Output() info = new EventEmitter();

    loadingJobInfo: boolean = false;
    jobInfo: any = {};
    localSubmitting: boolean = false;
    user: any = {};

    // frame

    promoId: number = null;

    constructor(
        private DefaultService: DefaultService,
        private HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService,
        private TranslatePipe: TranslatePipe
    ) {
        this.AuthenticationService.getUser().subscribe((next) => {
            this.user = next;
        });
    }

    ngOnInit(): void {
        const params = this.HelpersService.getParams();

        if (params?.source === 'controlcenter') {
            this.isControlcenter = true;
        }
        if (this.isControlcenter) {
            this.promoId = params.promoId;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // if (this.isInfo && changes.job && changes.job.currentValue?.id) {
        if (this.isInfo && changes.expanded && changes.expanded.currentValue && this.job.id) {
            this.loadingJobInfo = true;
            this.DefaultService.planningGetActionDetail(this.job.id, this.promoId).subscribe((next: any) => {
                this.jobInfo = next;
                setTimeout(() => {
                    this.loadingJobInfo = false;
                }, 400);
            });
        }
        if (changes.submitting && changes.submitting.currentValue === false) {
            this.localSubmitting = false;
        }
    }

    clickLocation($event, item) {
        $event.preventDefault();
        const result = {
            lat: item.location?.latitude,
            lng: item.location?.longitude,
            address: item.location?.address,
            title: item.label,
            locationId: item.location?.locationid
        };
        window.location.href = `#geo${JSON.stringify(result)}`;
        setTimeout(() => {
            window.location.href = `#`;
        }, 1);
    }

    getTypeDescr(type: string, typeDescr: string) {
        if (typeDescr) typeDescr = typeDescr.toLowerCase();
        switch (type) {
            case 'AR':
            case 'OS':
            case 'VS':
            case 'SS':
                return this.TranslatePipe.transform('job_typedescr_this_job');
            case 'BR':
                switch (this.user?.LANGUAGE) {
                    case 'du':
                        return 'deze ' + typeDescr;
                    case 'fr':
                        return 'ce ' + typeDescr;
                    case 'en':
                        return 'this ' + typeDescr;
                }
                break;
            case 'CS':
                switch (this.user?.LANGUAGE) {
                    case 'du':
                        return 'deze ' + typeDescr;
                    case 'fr':
                        return 'ce ' + typeDescr;
                    case 'en':
                        return 'this ' + typeDescr;
                }
                break;
            default:
                return '';
        }
    }
}
