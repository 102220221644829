import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { DefaultService } from '../../../../shared/api/api/default.service';
import { AuthenticationService } from './authentication.service';
import { TranslatorService } from './translator.service';

@Injectable({
    providedIn: 'root'
})
export class InitService {
    constructor(
        private TranslatorService: TranslatorService,
        private DefaultService: DefaultService,
        private AuthenticationService: AuthenticationService
    ) {}

    initialize() {
        return new Promise<void>((resolve, reject) => {
            this.DefaultService.userMe().subscribe((next) => {
                this.AuthenticationService.setUser(next);
                this.TranslatorService.init(next.LANGUAGE || 'en').subscribe((next) => {
                    resolve();
                });
            });
        });
    }
}
