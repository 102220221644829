<main class="d-flex flex-column" *ngIf="ready">
    <!-- <div style="height: 48px; background-color: black; display: flex; align-items: center;justify-content: center;color: rgb(68, 68, 68);">temp</div> -->
    <div class="d-flex flex-column flex-grow-1">
        <router-outlet></router-outlet>
    </div>
    <!-- <div style="height: 70px; background-color: black; display: flex; align-items: center;justify-content: center;color: rgb(68, 68, 68);">temp</div> -->
</main>
<!-- <app-online-status 
    [onlineStatusMessage]="connectionStatusMessage" 
    [onlineStatus]="connectionStatus">
</app-online-status> -->