import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    private user: any = new BehaviorSubject(null);

    constructor() {}

    setUser(value) {
        this.user.next(value);
    }

    getUser() {
        return this.user.asObservable();
    }
}
