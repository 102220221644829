import { Component, HostListener, OnInit } from '@angular/core';
import * as moment from 'moment';
import { DefaultService } from '../../../../../shared/api/api/default.service';
import { PullToRefreshService } from '@piumaz/pull-to-refresh';
import { HelpersService } from 'src/app/services/helpers.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

type Tab = 'availability' | 'jobs' | 'open';

@Component({
    selector: 'app-planning',
    templateUrl: './planning.component.html',
    styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit {
    // calendar
    currentMonth: string = null;
    currentMonthDayOffset: number[] = [];
    weekDays: string[] = [];
    currentMonthDays: any[] = [];
    selectedDays: any = {};
    selectedDay: any = null;
    allowPrevMonth: boolean = true;

    ready: boolean = false;
    slideoutExpanded: boolean = false;
    slideoutTab: Tab = 'availability';
    unavailabilityFormActive: boolean = false;
    unavailabilityFORM: any[] = [];
    selectDatesActive: boolean = false;
    editSelectedDayActive: boolean = false;
    keyboardActive: boolean = false;
    keyboardActiveTimeout: boolean = false;
    jobDetail: any = {};
    jobDetailExpanded: boolean = false;
    legendExpanded: boolean = false;
    submitting: boolean | string = false;
    loadingPlanning: boolean = false;
    user: any = {};
    disablePullToRefresh: boolean = false;

    isAndroid: boolean = false;
    isIOS: boolean = false;
    isMobileDevice: boolean = false;
    isMerch: boolean = false;

    temp: any = null;

    // frame
    isControlcenter: boolean = false;
    promoId: number = null;

    planning: any[] = [];

    private swipeCoord?: [number, number];
    private swipeTime?: number;

    constructor(
        private DefaultService: DefaultService,
        private PullToRefreshService: PullToRefreshService,
        private HelpersService: HelpersService,
        private AuthenticationService: AuthenticationService
    ) {
        PullToRefreshService.refresh$().subscribe(() => {
            this.reloadPlanning();
        });
        this.AuthenticationService.getUser().subscribe((next) => {
            this.isMerch = next?.ROLE?.toLowerCase() === 'merch';
            this.slideoutTab = 'jobs';
            this.user = next;
        });
    }

    ngOnInit(): void {
        if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
            this.isAndroid = true;
        }
        if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
            this.isIOS = true;
        }
        if (this.isAndroid || this.isIOS) {
            this.isMobileDevice = true;
        }
        const params = this.HelpersService.getParams();

        if (params?.source === 'controlcenter') {
            this.isControlcenter = true;
        }
        if (this.isControlcenter) {
            this.promoId = params.promoId;
        }
        this.currentMonth = moment().startOf('month').format('YYYY-MM-DD');
        const weekDays = [];
        for (let i = 0; i < 7; i++) {
            weekDays.push(
                moment()
                    .day(i + 1)
                    .format('dd')
                    .charAt(0)
            );
        }
        this.weekDays = weekDays;

        this.loadingPlanning = true;
        this.DefaultService.planningGetPlanningForPromo(this.promoId).subscribe((next: any) => {
            const result = next.map((item) => {
                return {
                    ...item,
                    isPast: moment(item.date).isBefore(moment(moment().format('YYYY-MM-DD')))
                };
            });
            this.planning = result;
            this.loadingPlanning = false;
            this.setSelectedDay(moment().format('YYYY-MM-DD'));
            this.changeMonth();
        });

        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        this.temp = {
            vw: vw,
            vh: vh
        };
    }

    @HostListener('window:hashchange', ['$event'])
    hashChanged(e) {
        // console.log('hashchanged', window.location.hash);
        if (window.location.hash == '#refresh') {
            // console.log('reload');
            this.reloadPlanning();
        }
        setTimeout(() => {
            window.location.href = '#';
        }, 1);
    }

    reloadPlanning(tab?: Tab) {
        this.loadingPlanning = true;
        this.DefaultService.planningGetPlanningForPromo(this.promoId).subscribe((next: any) => {
            const result = next.map((item) => {
                return {
                    ...item,
                    isPast: moment(item.date).isBefore(moment(moment().format('YYYY-MM-DD')))
                };
            });
            this.planning = result;
            this.setSelectedDay(this.selectedDay.date, tab);
            this.changeMonth();
            this.loadingPlanning = false;
            this.PullToRefreshService.dismiss();
            this.submitting = false;
            this.editSelectedDayActive = false;
            this.submitting = false;
            if (tab === 'availability') {
                this.unavailabilityFormActive = false;
                this.slideoutExpanded = false;
                this.keyboardActiveTimeout = false;
                if (Object.keys(this.selectedDays)?.length) {
                    this.cancelSelectDates();
                }
            }
            this.slideoutWrappersToTop();
        });
    }

    setSelectedDay(date, tab?: Tab) {
        this.selectedDay = {
            date: date,
            ...this.getDayInfo(date),
            isPast: moment(date).isBefore(moment(moment().format('YYYY-MM-DD')))
        };
        const selectedDayHasAvailabilityData =
            this.selectedDay.available === true ||
            this.selectedDay.available === false ||
            this.selectedDay.unavailable?.length;

        if (this.isMerch) {
            this.slideoutTab = 'jobs';
            return;
        }
        if (!this.isControlcenter) {
            if (tab) {
                this.slideoutTab = tab;
                return;
            }
            // accepted / to accept jobs -> tab jobs
            if (this.selectedDay?.actionsToAccept?.length || this.selectedDay?.actionsAccepted?.length) {
                this.slideoutTab = 'jobs';
                // open jobs / applied open jobs && availability filled -> tab open
            } else if (
                (this.selectedDay?.actionsOpen?.length || this.selectedDay?.actionsApplied?.length) &&
                selectedDayHasAvailabilityData
            ) {
                this.slideoutTab = 'open';
            } else this.slideoutTab = 'availability';
        } else this.slideoutTab = 'availability';
    }

    prev() {
        this.currentMonth = moment(this.currentMonth).subtract(1, 'M').format('YYYY-MM-DD');
        this.changeMonth();
    }

    next() {
        this.currentMonth = moment(this.currentMonth).add(1, 'M').format('YYYY-MM-DD');
        this.changeMonth();
    }

    changeMonth() {
        this.currentMonthDays = this.getDaysArrayByMonth(this.currentMonth).map((item) => {
            const info: any = this.getDayInfo(item);
            if (info.unavailable?.length) {
                info.unavailable = info.unavailable.map((item) => {
                    return {
                        ...this.getRangeObject(item.from, item.until)
                    };
                });
            }
            return {
                date: item,
                ...info,
                isPast: moment(item).isBefore(moment(moment().format('YYYY-MM-DD')))
            };
        });
        const offset = [];
        let firstDayOffset = moment(this.currentMonth).day() - 1;
        if (firstDayOffset === -1) {
            firstDayOffset = 6;
        }
        for (let i = 0; i < firstDayOffset; i++) {
            offset.push(i);
        }
        this.currentMonthDayOffset = offset;
        const timeFrom = moment(this.currentMonth);
        const timeUntil = moment(moment().startOf('month').format('YYYY-MM-DD'));
        const monthDifference = timeUntil.diff(timeFrom, 'months', true);
        if (monthDifference < 11) {
            this.allowPrevMonth = true;
        } else this.allowPrevMonth = false;
        if (!this.isControlcenter && !window.location.href.includes('#ready')) {
            setTimeout(() => {
                window.location.href = '#ready';
                setTimeout(() => {
                    window.location.href = `#`;
                }, 1);
            }, 500);
        }
        this.ready = true;
    }

    getDaysArrayByMonth(month) {
        var daysInMonth = moment(month).daysInMonth();
        var arrDays = [];

        while (daysInMonth) {
            var current = moment(month).date(daysInMonth).format('YYYY-MM-DD');
            arrDays.unshift(current);
            daysInMonth--;
        }

        return arrDays;
    }

    getDayInfo(date) {
        let result = {};
        const info = this.planning?.find((item) => {
            return item.date === date;
        });
        if (info) result = { ...info };
        return result;
    }

    clickDay(date) {
        this.unavailabilityFormActive = false;
        this.editSelectedDayActive = false;
        if (!this.selectDatesActive) {
            this.selectedDays = {};
            this.setSelectedDay(date);
        } else {
            if (!this.isMerch) {
                this.slideoutTab = 'availability';
            } else this.slideoutTab = 'jobs';
            const result = { ...this.selectedDays };
            if (result[date]) {
                delete result[date];
            } else result[date] = this.getDayInfo(date);
            this.selectedDays = result;
            this.selectedDay = {};
        }
    }

    selectDates() {
        this.selectDatesActive = true;
        if (!this.isMerch) {
            this.slideoutTab = 'availability';
        } else this.slideoutTab = 'jobs';
        this.unavailabilityFormActive = false;
        this.selectedDays = {};
        this.selectedDays[this.selectedDay.date] = this.getDayInfo(this.selectedDay.date);
    }

    changetab(tab: 'availability' | 'jobs' | 'open') {
        if (!this.isMerch) {
            this.slideoutTab = tab;
        } else this.slideoutTab = 'jobs';
        this.unavailabilityFormActive = false;

        this.slideoutWrappersToTop();
    }

    slideoutWrappersToTop() {
        var slideoutWrappers = document.getElementsByClassName('slideout-content');
        for (var i = 0; i < slideoutWrappers.length; i++) {
            slideoutWrappers[i].scrollTop = 0;
        }
    }

    cancelSelectDates() {
        const firstDay = Object.keys(this.selectedDays)?.length ? Object.keys(this.selectedDays)[0] : null;
        this.selectedDays = {};
        this.selectDatesActive = false;
        this.setSelectedDay(firstDay || moment().format('YYYY-MM-DD'));
    }

    confirmSelectDates() {
        this.selectDatesActive = false;
    }

    setUnavailabilityFormActive() {
        if (Object.keys(this.selectedDays)?.length || !this.selectedDay.unavailable?.length) {
            this.unavailabilityFORM = [{ from: null, until: null, localFrom: '', localUntil: '' }];
        } else {
            this.unavailabilityFORM = [...this.selectedDay.unavailable].map((item) => {
                return {
                    localFrom: item.from?.replace(':', ''),
                    localUntil: item.until?.replace(':', '')
                };
            });
            this.onChangeUnavailability();
            setTimeout(() => {
                this.onBlurUnavailability();
            }, 10);
        }
        this.unavailabilityFormActive = true;
        this.slideoutWrappersToTop();
        if (window.innerHeight <= 520) {
            this.slideoutExpanded = true;
        }
    }
    addUnavailability() {
        this.unavailabilityFORM.push({ from: null, until: null, localFrom: '', localUntil: '' });
    }
    onChangeUnavailability() {
        setTimeout(() => {
            for (let i = 0; i < this.unavailabilityFORM.length; i++) {
                const range = this.unavailabilityFORM[i];
                if (!range.localFrom || !range.localUntil) {
                    range.from = null;
                    range.until = null;
                    continue;
                }
                if (range.localFrom.length !== 4 || range.localUntil.length !== 4) {
                    range.from = null;
                    range.until = null;
                    continue;
                }

                const rangeObject: any = this.getRangeObject(range.localFrom, range.localUntil);

                if (!rangeObject) {
                    range.from = null;
                    range.until = null;
                    continue;
                } else {
                    range.from = rangeObject.from;
                    range.until = rangeObject.until;
                    range.percentageOfDay = rangeObject.percentageOfDay;
                    range.rotateAmount = rangeObject.rotateAmount;
                    range.invalid = rangeObject.invalid;
                }
            }
        }, 1);
    }

    getRangeObject(from: string, until: string) {
        // expects from: '00:00', until: '00:00' (or 0000 0000)
        from = from.replace(':', '');
        until = until.replace(':', '');
        const timeFrom = moment();
        const hoursFrom = parseInt(from.substring(0, 2));
        const minutesFrom = parseInt(from.substring(2, 4));
        const timeUntil = moment();
        const hoursUntil = parseInt(until.substring(0, 2));
        const minutesUntil = parseInt(until.substring(2, 4));
        timeFrom.set({
            hour: hoursFrom,
            minute: minutesFrom,
            second: 0,
            millisecond: 0
        });
        timeUntil.set({
            hour: hoursUntil,
            minute: minutesUntil,
            second: 0,
            millisecond: 0
        });
        if (!timeFrom?.isValid() || !timeUntil?.isValid()) {
            return;
        }

        const difference = moment.duration(timeUntil.diff(timeFrom));
        const minutes = difference.asMinutes();
        const percentageOfDay = (minutes / 1440) * 100;

        const differenceOffset = moment.duration(
            timeFrom.diff(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
        );
        const minutesOffset = differenceOffset.asMinutes();
        const percentageOfDayOffset = (minutesOffset / 1440) * 100;

        const rotateAmount = 180 + 360 * (percentageOfDayOffset / 100);

        return {
            percentageOfDay: percentageOfDay,
            rotateAmount: rotateAmount,
            from: timeFrom,
            until: timeUntil,
            invalid: timeFrom.isAfter(timeUntil)
        };
    }

    onBlurUnavailability() {
        this.keyboardActive = false;
        setTimeout(() => {
            this.keyboardActiveTimeout = false;
        }, 200);
        setTimeout(() => {
            for (let i = 0; i < this.unavailabilityFORM.length; i++) {
                const range = this.unavailabilityFORM[i];
                // always clear invalid state
                range.invalid = false;
                if (range.localFrom?.length === 2) {
                    range.localFrom = range.localFrom += '00';
                }
                if (range.localUntil?.length === 2) {
                    range.localUntil = range.localUntil += '00';
                }
                if (!range.localFrom || !range.localUntil) {
                    range.from = null;
                    range.until = null;
                    continue;
                }
                if (range.localFrom?.length !== 4 || range.localUntil?.length !== 4) {
                    range.from = null;
                    range.until = null;
                    continue;
                }
                const timeFrom = moment();
                const hoursFrom = parseInt(range.localFrom.substring(0, 2));
                const minutesFrom = parseInt(range.localFrom.substring(2, 4));
                const timeUntil = moment();
                const hoursUntil = parseInt(range.localUntil.substring(0, 2));
                const minutesUntil = parseInt(range.localUntil.substring(2, 4));
                timeFrom.set({
                    hour: hoursFrom,
                    minute: minutesFrom,
                    second: 0,
                    millisecond: 0
                });
                timeUntil.set({
                    hour: hoursUntil,
                    minute: minutesUntil,
                    second: 0,
                    millisecond: 0
                });
                if (timeFrom.isAfter(timeUntil)) {
                    range.from = null;
                    range.until = null;
                    range.localFrom = null;
                    range.localUntil = null;
                }
                this.onChangeUnavailability();
            }
        }, 1);
    }

    onFocusUnavailability() {
        this.keyboardActive = true;
        this.keyboardActiveTimeout = true;
        if (this.isAndroid) {
            this.slideoutExpanded = true;
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 40);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 40);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 50);
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 100);
        }
    }

    // mixed
    submitUnavailabilities() {
        this.keyboardActiveTimeout = true;
        this.submitting = true;
        this.loadingPlanning = true;
        setTimeout(() => {
            const FORM: any = {
                availabilities: []
            };
            const unavailable = [];
            for (let i = 0; i < this.unavailabilityFORM.length; i++) {
                const time = this.unavailabilityFORM[i];
                if (time?.from?.isValid() && time?.until?.isValid()) {
                    unavailable.push({
                        from: time.from.format('HH:mm'),
                        until: time.until.format('HH:mm')
                    });
                }
            }
            if (Object.keys(this.selectedDays)?.length) {
                for (const key in this.selectedDays) {
                    FORM.availabilities.push({
                        date: key,
                        unavailable: unavailable
                    });
                }
            } else {
                FORM.availabilities.push({
                    date: this.selectedDay.date,
                    unavailable: unavailable
                });
            }
            if (Object.keys(this.selectedDays)?.length) {
                this.cancelSelectDates();
            }
            this.DefaultService.planningChangeAvailability(FORM, this.promoId).subscribe(
                (next) => {
                    // this.submitting = false;

                    this.reloadPlanning('availability');
                    // this.unavailabilityFormActive = false;
                    // this.slideoutExpanded = false;
                    // this.keyboardActiveTimeout = false;
                },
                (error) => {
                    this.submitting = false;
                }
            );
        }, 10);
    }

    // available
    setAvailable() {
        this.submitting = 'availability';
        this.loadingPlanning = true;
        const FORM: any = {
            availabilities: []
        };
        if (Object.keys(this.selectedDays)?.length) {
            for (const key in this.selectedDays) {
                FORM.availabilities.push({
                    date: key,
                    available: true
                });
            }
        } else {
            FORM.availabilities.push({
                date: this.selectedDay.date,
                available: true
            });
        }
        this.unavailabilityFormActive = false;
        // if (Object.keys(this.selectedDays)?.length) {
        //     this.cancelSelectDates();
        // }
        this.DefaultService.planningChangeAvailability(FORM, this.promoId).subscribe((next) => {
            setTimeout(() => {
                // this.submitting = false;
                this.reloadPlanning('availability');
            }, 400);
        });
    }

    // unavailable
    setUnavailable() {
        this.submitting = 'unavailability';
        this.loadingPlanning = true;
        const FORM: any = {
            availabilities: []
        };
        if (Object.keys(this.selectedDays)?.length) {
            for (const key in this.selectedDays) {
                FORM.availabilities.push({
                    date: key,
                    available: false
                });
            }
        } else {
            FORM.availabilities.push({
                date: this.selectedDay.date,
                available: false
            });
        }
        this.unavailabilityFormActive = false;
        if (Object.keys(this.selectedDays)?.length) {
            this.cancelSelectDates();
        }
        this.DefaultService.planningChangeAvailability(FORM, this.promoId).subscribe((next) => {
            setTimeout(() => {
                // this.submitting = false;
                this.reloadPlanning('availability');
            }, 400);
        });
    }

    allowSubmitUnavailabilities() {
        return (
            this.unavailabilityFORM.filter((item) => {
                if (!item.from || !item.until) {
                    return false;
                }
                const timeFrom = moment();
                const hoursFrom = parseInt(item.localFrom.substring(0, 2));
                const minutesFrom = parseInt(item.localFrom.substring(2, 4));
                const timeUntil = moment();
                const hoursUntil = parseInt(item.localUntil.substring(0, 2));
                const minutesUntil = parseInt(item.localUntil.substring(2, 4));
                timeFrom.set({
                    hour: hoursFrom,
                    minute: minutesFrom,
                    second: 0,
                    millisecond: 0
                });
                timeUntil.set({
                    hour: hoursUntil,
                    minute: minutesUntil,
                    second: 0,
                    millisecond: 0
                });
                if (timeFrom.isAfter(timeUntil)) {
                    return false;
                }
                return true;
            })?.length >= 1
        );
    }

    selectedDaysStatus() {
        // pos | neg | mixed | null
        if (!Object.keys(this.selectedDays)?.length) return;
        const selected: any[] = Object.values(this.selectedDays);
        const hasPos = selected.filter((item) => {
            return item.available === true;
        })?.length;
        const hasNeg = selected.filter((item) => {
            return item.available === false;
        })?.length;
        const hasMixed = selected.filter((item) => {
            return item.unavailable?.length;
        })?.length;
        const hasEmpty = selected.filter((item) => {
            return item.available === undefined && !item.unavailable?.length;
        })?.length;
        if (hasEmpty === selected.length) {
            return null;
        }
        if (hasEmpty || hasMixed || (hasPos && hasNeg)) {
            return 'mixed';
        } else if (hasPos) {
            return 'pos';
        } else if (hasNeg) {
            return 'neg';
        }
        return null;
    }

    expandJobInfo(job) {
        this.jobDetail = job;
        this.jobDetailExpanded = true;
    }

    closeJobInfo() {
        this.jobDetailExpanded = false;
    }

    expandLegend() {
        this.legendExpanded = true;
    }

    closeLegend() {
        this.legendExpanded = false;
    }

    acceptJob(job) {
        this.submitting = true;
        this.DefaultService.planningAcceptOrRefuse({ resolution: 'accept' }, job.id).subscribe(
            (next) => {
                // this.submitting = false;
                this.reloadPlanning();
                if (!this.isControlcenter) {
                    window.location.href = '#counter';
                    setTimeout(() => {
                        window.location.href = `#`;
                    }, 1);
                }
            },
            (error) => {
                // this.submitting = false;
                this.reloadPlanning();
            }
        );
    }

    refuseJob(job) {
        this.submitting = true;
        this.DefaultService.planningAcceptOrRefuse({ resolution: 'refuse' }, job.id).subscribe(
            (next) => {
                // this.submitting = false;
                this.reloadPlanning();
            },
            (error) => {
                // this.submitting = false;
                this.reloadPlanning();
            }
        );
    }

    applyJob(job) {
        this.submitting = true;
        this.DefaultService.planningApplyOrWithdraw({ resolution: 'apply' }, job.id).subscribe(
            (next) => {
                // this.submitting = false;
                this.reloadPlanning('open');
            },
            (error) => {
                // this.submitting = false;
                this.reloadPlanning('open');
            }
        );
    }

    withdrawJob(job) {
        this.submitting = true;
        this.DefaultService.planningApplyOrWithdraw({ resolution: 'withdraw' }, job.id).subscribe(
            (next) => {
                // this.submitting = false;
                this.reloadPlanning('open');
            },
            (error) => {
                // this.submitting = false;
                this.reloadPlanning('open');
            }
        );
    }

    // swipe

    swipe(e: TouchEvent, when: string): void {
        const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
        const time = new Date().getTime();
        if (when === 'start') {
            this.swipeCoord = coord;
            this.swipeTime = time;
        } else if (when === 'end') {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (
                duration < 1000 && //
                Math.abs(direction[0]) > 30 && // Long enough
                Math.abs(direction[0]) > Math.abs(direction[1] * 3)
            ) {
                // Horizontal enough
                const swipe = direction[0] < 0 ? 'next' : 'previous';
                if (swipe === 'next') {
                    this.next();
                } else {
                    this.prev();
                }
            }
        }
    }

    touchSlideout(e: TouchEvent, when: string): void {
        if (when === 'start') {
            this.disablePullToRefresh = true;
        } else this.disablePullToRefresh = false;
    }
}
