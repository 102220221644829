<div class="job" *ngIf="job">
    <!-- general -->
    <div class="job-descr">
        <div class="job-company-icon" [style.background-image]="'url(' + job.agency_icon + ')'"></div>
        <div class="job-descr-info">
            <div class="job-head">
                <div class="job-title">{{job.project}}</div>
                <button *ngIf="!isInfo" class="job-more-info" (click)="info.emit(job)">{{'job_more_info' | translate}}<i></i></button>
            </div>
            <ng-container *ngIf="!job.simpleView">
                <div class="job-planning">
                    <ng-container *ngIf="job.hasPickup">{{'job_pick_up' | translate}} <span class="arrow">&#10140;</span> </ng-container>
                        <span class="location" *ngIf="job.location"> {{job.location}} </span>
                        <span class="location-to-be-determined" *ngIf="!job.location"> {{'job_location_tbd' | translate}} </span>
                    <ng-container *ngIf="job.hasDropoff"><span class="arrow">&#10140;</span> {{'job_drop_off' | translate}} </ng-container>
                </div>
            </ng-container>
            <div class="job-timing-and-type">
                <div *ngIf="!job.simpleView" class="job-timing">
                    <table>
                        <tr>
                            <td>{{'job_job_start' | translate}}</td>
                            <td>{{job.start}}</td>
                        </tr>
                        <tr>
                            <td>{{'job_job_end' | translate}}</td>
                            <td>{{job.end}}</td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="job.simpleView" class="job-title-descr">
                    <!-- temp temp temp -->
                </div>
                <div class="job-type">
                    {{job.typeDescr}}
                    <i *ngIf="job.type" [style.background-image]="'url(' + '/collabor8-planning/assets/img/job-types/icon-' + job.type + '.svg' + ')'"></i>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="!isControlcenter">
        <!-- to accept -->
        <div class="job-options" *ngIf="toAccept">
            <button class="job-accept" [class.saving]="localSubmitting" (click)="localSubmitting = true; accept.emit(job)">{{'job_btn_accept' | translate}}</button>
            <button class="job-decline" [class.saving]="localSubmitting" (click)="localSubmitting = true; decline.emit(job)">{{'job_btn_decline' | translate}}</button>
        </div>
        <!-- is open -->
        <div class="job-options applied-wrap" *ngIf="isOpen">
            <div class="applied" *ngIf="withdraw.observers?.length">
                <div class="title">{{'job_applied_title_1' | translate}} {{getTypeDescr(job.type, job.typeDescr)}}{{'job_applied_title_2' | translate}}</div>
                <!-- <div class="descr">{{'job_applied_descr' | translate}}</div> -->
            </div>
            <button class="job-apply" *ngIf="apply.observers?.length" [class.saving]="localSubmitting" (click)="localSubmitting = true; apply.emit(job)">{{'job_btn_apply' | translate}} {{getTypeDescr(job.type, job.typeDescr)}}</button>
            <button class="job-withdraw" *ngIf="withdraw.observers?.length" [class.saving]="localSubmitting" (click)="localSubmitting = true; withdraw.emit(job)">{{'job_btn_withdraw' | translate}}</button>
        </div>
    </ng-container>
    <!-- is info -->
    <div class="job-info" *ngIf="isInfo">
        <div class="job-info--inner" *ngIf="jobInfo.timeline">
            <ng-container *ngIf="loadingJobInfo">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-container *ngIf="!loadingJobInfo">
                <!-- timeline -->
                <div class="job-timeline" *ngIf="jobInfo?.timeline?.length">
                    <ng-container *ngFor="let item of jobInfo.timeline">
                        <div class="job-timeline-item">
                            <div class="left">
                                {{item.time}}
                            </div>
                            <div class="center">
                                <i class="timeline-icon icon-timeline-{{item.type}}"></i>
                                <div class="line"></div>
                            </div>
                            <div class="right">
                                <div class="title">{{item.label}}</div>
                                <div class="content">
                                    <!-- location -->
                                    <ng-container *ngIf="item.location">
                                        <div (click)="clickLocation($event, item)" class="content-type content--address">
                                            <div class="icon-wrap"><i></i></div>
                                            <div class="content-wrap">{{item.location.address}}</div>
                                        </div>
                                        <!-- <a (click)="clickLocation($event, item)" [href]="'http://maps.google.com/maps?q=' + item.location.latitude + ',' + item.location.longitude" class="content-type content--address" target="_blank"> -->
                                    </ng-container>
                                    <!-- promos -->
                                    <ng-container *ngIf="item.promos?.length">
                                        <div class="content-type content--promo" target="_blank">
                                            <div class="icon-wrap"><i></i></div>
                                            <div class="content-wrap">
                                                <ng-container *ngFor="let promo of item.promos">
                                                    <div class="promo-item">
                                                        <div class="avatar" [style.border-color]="promo.color" [style.background-image]="'url(' + promo.thumb + ')'"></div>
                                                        <div class="right">
                                                            <div class="name">{{promo.name}}</div>
                                                            <div class="descr">{{promo.descr}}</div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <!-- car -->
                                    <ng-container *ngIf="item.type === 'car'">
                                        <div class="content-type content--car" target="_blank">
                                            {{item.description}}
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
        <div class="job-info--foot" *ngIf="!loadingJobInfo">
            <!-- wage -->
            <div class="foot-row" *ngIf="jobInfo.rate">
                <div class="left">{{'job_foot_label_wage' | translate}}</div>
                <div class="right">
                    <i class="icon-wage"></i>
                    {{jobInfo.rate}} {{'job_foot_wage_suffix' | translate}}
                </div>
            </div>
            <!-- time -->
            <div class="foot-row" *ngIf="jobInfo.duration">
                <div class="left">{{'job_foot_label_time' | translate}}</div>
                <div class="right">
                    <i class="icon-time"></i>
                    {{jobInfo.duration}}
                </div>
            </div>
            <!-- disclaimer -->
            <div *ngIf="jobInfo.rate" class="foot-row-disclaimer">{{'job_foot_disclaimer' | translate}}</div>
        </div>
    </div>
</div>