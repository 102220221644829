import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { NgxMaskModule } from 'ngx-mask';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiModule, Configuration, ConfigurationParameters } from '../../../shared/api/';
import { ClickOutsideDirective } from './utils/directives';
import {
    displayPipe,
    listPipe,
    callbackPipe,
    centPipe,
    objLenPipe,
    formatNumberPipe,
    httpsPipe,
    SafePipe,
    toFixedPipe,
    vatPipe,
    formatTsPipe,
    customCurrencyPipe,
    percentagePipe,
    TranslatePipe,
    numberPipe
} from './utils/pipes';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { SpinnerComponent } from './components/misc/spinner/spinner.component';
import { InitService } from './services/init.service';
import { PlanningComponent } from './pages/planning/planning.component';
import { OnlineStatusComponent } from './components/misc/online-status/online-status.component';
import { JobComponent } from './components/layout/job/job.component';
import { PullToRefreshModule } from '@piumaz/pull-to-refresh';

@NgModule({
    declarations: [
        AppComponent,
        ClickOutsideDirective,
        formatTsPipe,
        displayPipe,
        listPipe,
        callbackPipe,
        centPipe,
        objLenPipe,
        formatNumberPipe,
        httpsPipe,
        SafePipe,
        toFixedPipe,
        vatPipe,
        TranslatePipe,
        customCurrencyPipe,
        percentagePipe,
        numberPipe,
        SpinnerComponent,
        PlanningComponent,
        OnlineStatusComponent,
        JobComponent
    ],
    imports: [
        PullToRefreshModule,
        BrowserAnimationsModule,
        BrowserModule,
        AngularMyDatePickerModule,
        CommonModule,
        NgxMaskModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot(() => {
            return new Configuration({ basePath: '/api' });
        })
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
        { provide: APP_INITIALIZER, useFactory: initializeApp, multi: true, deps: [InitService] },
        CurrencyPipe,
        customCurrencyPipe,
        percentagePipe,
        TranslatePipe,
        numberPipe,
        formatTsPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}

export function initializeApp(InitService: InitService) {
    return (): Promise<any> => {
        return InitService.initialize();
    };
}
