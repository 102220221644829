import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { DefaultService } from '../../../../shared/api/api/default.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class TranslatorService {
    private translations$ = new BehaviorSubject<Object>({});
    private language$ = new BehaviorSubject<any>(null);
    private ready: boolean = false;

    constructor(
        private DefaultService: DefaultService,
        public Route: ActivatedRoute,
        @Inject(DOCUMENT) private document: Document
    ) {}

    public init(language: any) {
        this.setLanguage(language);
        moment.locale(this.getLocale(language));
        this.document.documentElement.lang = language;
        return this.DefaultService.userInfoGetTranslations('collabor8-planning', language).pipe(
            tap((next) => {
                this.translations$.next(next);
                this.ready = true;
            })
        );
    }

    public getTranslation(key: string): string {
        if (!key || !this.ready) return key;
        key = key.toLowerCase();
        if (this.translations$.getValue()[key]) {
            return this.translations$.getValue()[key];
        } else {
            this.DefaultService.userInfoAddNewVarString({ code: key }, 'collabor8-planning').subscribe();
            return key;
        }
    }

    public getTranslations(): Observable<Object> {
        return this.translations$.asObservable();
    }

    public getLanguage(): Observable<any> {
        return this.language$.asObservable();
    }

    public getLanguageValue(): any {
        return this.language$.getValue();
    }

    public setLanguage(language: any) {
        this.language$.next(language);
    }

    public getLocale(language?) {
        if (!language) {
            language = this.language$.getValue();
        }
        switch (language) {
            case 'fr':
                return 'fr-FR';
            case 'en':
                return 'en-GB';
            default:
                return 'nl-BE';
        }
    }

    public getDayLabels() {
        return {
            su: this.getTranslation('day_su'),
            mo: this.getTranslation('day_mo'),
            tu: this.getTranslation('day_tu'),
            we: this.getTranslation('day_we'),
            th: this.getTranslation('day_th'),
            fr: this.getTranslation('day_fr'),
            sa: this.getTranslation('day_sa')
        };
    }
}
